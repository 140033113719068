import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Teams and Skills settings: Added hours to exceptions timetable`}</li>
      <li parentName="ul">{`SMARKIO: Added group provider as a filter on email statistics API`}</li>
      <li parentName="ul">{`Queue position and estimated time: Added audio files for different languages*`}</li>
      <li parentName="ul">{`Bug fix: redefined settings for Music on Hold`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      